/* global window, document */
import "core-js/modules/es6.set"
import "core-js/modules/es6.map"
import { register } from 'swiper/element/bundle'

export { default as wrapRootElement } from "./src/state/ReduxWrapper"

const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }
}

export const onInitialClientRender = () => {
  register();
}