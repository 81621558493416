import { produce } from "immer"

const initialState = {
  isVisible: false,
  isIframeLoaded: false,
  sourceUri: "",
}

const SET_SOURCE = "SET_SOURCE"
export const setSource = src => ({ type: SET_SOURCE, src })

const SET_VISIBLE = "SET_VISIBLE"
export const setVisible = isVisible => ({ type: SET_VISIBLE, isVisible })

const SET_IFRAME_LOADED = "SET_IFRAME_LOADED"
export const setIframeLoaded = isLoaded => ({
  type: SET_IFRAME_LOADED,
  isLoaded,
})

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_SOURCE:
        draft.sourceUri = action.src
        if (state.sourceUri !== action.src) {
          draft.isIframeLoaded = false
        }
        break
      case SET_VISIBLE:
        draft.isVisible = action.isVisible
        if (action.isVisible && !state.isVisible) {
          draft.isIframeLoaded = false
        }
        break
      case SET_IFRAME_LOADED:
        draft.isIframeLoaded = action.isLoaded
        break
    }
  })
