exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arbeid-js": () => import("./../../../src/pages/arbeid.js" /* webpackChunkName: "component---src-pages-arbeid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-utstyr-js": () => import("./../../../src/pages/utstyr.js" /* webpackChunkName: "component---src-pages-utstyr-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/GenericPage.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-record-article-js": () => import("./../../../src/templates/RecordArticle.js" /* webpackChunkName: "component---src-templates-record-article-js" */),
  "component---src-templates-record-list-js": () => import("./../../../src/templates/RecordList.js" /* webpackChunkName: "component---src-templates-record-list-js" */)
}

